import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import GoalAuditWorkFillter from './component/filter/GoalAuditWorkFilter'
import { CardNew } from '../../../components/card/Card'
import { Col, Row } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable'
import { ColumnsGoalAuditWork } from './utils/goalAuditWorkConstants'
import GoalAuditWorkDetailModal from './GoalAuditWorkDetailModal'
import { GET_GOAL_AUDIT_WORK_PAGINATION } from './graphql/Query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { encryptInput } from '../../../utilitys/crypto'
import { ButtonNew } from '../../../components/button/Button'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { DELETE_GOAL_AUDIT_WORK } from './graphql/Mutation'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

const Responsive = { sm: 24, md: 24, lg: 24 }

export default function GoalAuditWork(props) {
  const { t } = useTranslation()
  const [modalData, setModalData] = useState({
    visible: false,
    formType: 'view',
    auditJobTargetId: null
  })
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataList, setDataList] = useState([])
  const [filter, setFilter] = useState({})

  const [getGoalAuditWorkPaginationFn, getGoalAuditWorkPagination] = useLazyQuery(GET_GOAL_AUDIT_WORK_PAGINATION)
  const [deleteGoalAuditWorkFn, dataDeleteGoalAuditWork] = useMutation(DELETE_GOAL_AUDIT_WORK)

  const callApiSearch = () => {
    getGoalAuditWorkPaginationFn({
      variables: {
        input: encryptInput({
          filters: filter,
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'auditJobTargetId',
              sortType: 'DESC'
            }
          ]
        })
      }
    })
  }

  const showConfirmDelete = (auditJobTargetId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการลบข้อมูล?'),
      onCancel() {},
      onOk() {
        deleteGoalAuditWorkFn({
          variables: {
            input: encryptInput({
              auditJobTargetId
            })
          }
        })
      }
    })
  }

  useEffect(() => {
    if (getGoalAuditWorkPagination?.data) {
      let response = getGoalAuditWorkPagination?.data?.getGoalAuditWorkPagination
      setCount(response?.count)
      setDataList(
        response?.result.map((item) => ({
          ...item,
          key: item.auditJobTargetId,
          startDate: dateDisplay(item.startDate),
          endDate: dateDisplay(item.endDate),
          action: (
            <div style={{ textAlign: 'center' }}>
              <ButtonGroup
                menu={props.menu}
                icons={[
                  {
                    type: 'view',
                    onClick: () => {
                      setModalData({
                        ...modalData,
                        visible: true,
                        formType: 'view',
                        auditJobTargetId: item.auditJobTargetId
                      })
                    }
                  },
                  {
                    type: 'edit',
                    onClick: () => {
                      setModalData({
                        ...modalData,
                        visible: true,
                        formType: 'edit',
                        auditJobTargetId: item.auditJobTargetId
                      })
                    }
                  },
                  {
                    type: 'delete',
                    onClick: () => {
                      showConfirmDelete(item.auditJobTargetId)
                    }
                  }
                ]}
              />
            </div>
          )
        }))
      )
    } else {
      setDataList([])
      setCount(0)
    }
  }, [getGoalAuditWorkPagination?.data])

  useEffect(() => {
    callApiSearch()
  }, [page, limit, filter])

  useEffect(() => {
    if (dataDeleteGoalAuditWork.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataDeleteGoalAuditWork.refetch()
        }
      })
    }

    if (dataDeleteGoalAuditWork.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteGoalAuditWork.error)),
        onOk() {}
      })
    }
  }, [dataDeleteGoalAuditWork?.data])

  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/approveauditplan' },
    { label: t('เป้าหมายงานตรวจสอบ') }
  ]

  const onSuccess = () => {
    getGoalAuditWorkPagination.refetch()
    setModalData({ ...modalData, visible: false, auditJobTargetId: null })
  }

  const onClose = () => {
    setModalData({ ...modalData, visible: false, auditJobTargetId: null })
  }
  return (
    <>
      <BreadcrumbNew data={breadcrumbList} title={t('เป้าหมายงานตรวจสอบ')} />
      <GoalAuditWorkFillter getValue={(values) => setFilter(values)} />
      <CardNew topic={t('รายการเป้าหมายงานตรวจสอบ')} icon="AlignJustify" toggledrop={'false'}>
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={ColumnsGoalAuditWork}
              data={dataList}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              pageSize={limit}
              page={page}
              btnAdd={
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setModalData({
                        ...modalData,
                        visible: true,
                        formType: 'add',
                        auditJobTargetId: null
                      })
                    }}
                  >
                    {t('เพิ่มเป้าหมายงานตรวจสอบ')}
                  </ButtonNew>
                </>
              }
            />
          </Col>
        </Row>
      </CardNew>

      <GoalAuditWorkDetailModal {...modalData} onSuccess={onSuccess} onClose={onClose} />
    </>
  )
}
