import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalNew } from '../../../components/modal/Modal'
import { Alert } from '../../../components/alert/Alert'
import { InputNew } from '../../../components/input/Input'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { GET_GOAL_AUDIT_WORK_DETAIL } from './graphql/Query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { SAVE_GOAL_AUDIT_WORK } from './graphql/Mutation'
import { displayError } from '../../../utilitys/helper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectJobTypesCustom from './component/SelectJobTypesCustom'

export default function GoalAuditWorkDetailModal({
  visible = false,
  formType = 'view',
  auditJobTargetId = null,
  onSuccess = () => {},
  onClose = () => {}
}) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const [getGoalAuditWorkDetailFn, getGoalAuditWorkDetail] = useLazyQuery(GET_GOAL_AUDIT_WORK_DETAIL)
  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_GOAL_AUDIT_WORK)

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    switch (formType) {
      case 'edit':
        return t('แก้ไขเป้าหมายงานตรวจสอบ')
      case 'add':
        return t('เพิ่มเป้าหมายงานตรวจสอบ')
      default:
        return t('รายละเอียดเป้าหมายงานตรวจสอบ')
    }
  }

  const onFinish = () => {
    const fields = form.getFieldsValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          ...fields,
          auditJobTargetId
        }

        callSave({
          variables: {
            input: encryptInput(dataInput)
          }
        })
        onSuccess()
      }
    })
  }

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {}
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        }
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (auditJobTargetId && visible) {
      getGoalAuditWorkDetailFn({
        variables: {
          input: encryptInput({ auditJobTargetId })
        }
      })
    }
  }, [auditJobTargetId, visible])

  useEffect(() => {
    if (getGoalAuditWorkDetail?.data) {
      const response = getGoalAuditWorkDetail?.data?.getGoalAuditWorkDetail
      form.setFieldsValue({
        auditJobTargetName: response?.auditJobTargetName,
        jobTypeCode: response?.jobTypeCode,
        organizationCode: response?.organizationCode,
        startDate: response?.startDate,
        endDate: response?.endDate
      })
    }
  }, [getGoalAuditWorkDetail?.data])

  return (
    <React.Fragment>
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        typeAction={formType}
        testTitle={t(renderTitle())}
        type="medium"
        {...footerHide}
      >
        {loadingSave && <SpinnersNew />}
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8
          }}
          wrapperCol={{
            md: 8,
            lg: 8
          }}
          layout="horizontal"
          name="goalAuditWork"
        >
          <Form.Item
            label={t('ชื่องาน')}
            name="auditJobTargetName"
            rules={[{ required: true, message: t('กรุณากรอกชื่องาน') }]}
          >
            <InputNew maxLength={255} disabled={!['edit', 'add'].includes(formType)} placeholder={t('กรอกชื่องาน')} />
          </Form.Item>

          <Form.Item
            name="jobTypeCode"
            label={t('ประเภทงาน')}
            rules={[
              {
                required: true,
                message: t('กรุณาเลือกประเภทงาน')
              }
            ]}
          >
            <SelectJobTypesCustom
              placeholder={t('เลือกประเภทงาน')}
              formname={'goalAuditWork'}
              filters={{ jobflag: 1, isActive: 1 }}
              disabled={!['edit', 'add'].includes(formType)}
              handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
            />
          </Form.Item>

          <Form.Item
            name="organizationCode"
            label={t('สายงานตรวจสอบ')}
            rules={[{ required: true, message: t('กรุณาเลือกสายงานตรวจสอบ') }]}
          >
            <SelectOrganization
              placeholder={t('เลือกหน่วยงาน')}
              formname={'goalAuditWork'}
              filters={{ organizationChecked: 2, isActive: 1 }}
              handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
              disabled={!['edit', 'add'].includes(formType)}
            />
          </Form.Item>

          <Form.Item
            name="startDate"
            label={t('วันที่เริ่มต้น')}
            rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
          >
            <Datepicker
              setValueDateFn={async (data) => {
                await setIsMinDate(data), await form.setFieldsValue({ startDate: await data })
              }}
              onClear={() => {
                form.setFieldsValue({ startDate: null })
              }}
              placeholder={t('DD/MM/YYYY')}
              isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
              disabled={!['edit', 'add'].includes(formType)}
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            label={t('วันที่สิ้นสุด')}
            rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
          >
            <Datepicker
              setValueDateFn={async (data) => {
                await setIsMaxDate(data), await form.setFieldsValue({ endDate: await data })
              }}
              onClear={() => {
                form.setFieldsValue({ endDate: null })
              }}
              placeholder={t('DD/MM/YYYY')}
              isMinDate={isMinDate ? new Date(isMinDate) : null}
              disabled={!['edit', 'add'].includes(formType)}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
