import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../utilitys/crypto'
import { GET_MASTER_JOB_TYPES } from '../graphql/Query'
import { displayText } from '../../../../utilitys/helper'
import { Select } from '../../../../components/select/Select'

const SelectJobTypesCustom = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const [isData, isSetData] = useState([])

  const { isActive, jobGroupCode, jobflag } = filters || {}
  const [getMasterJobTypesFn, getMasterJobTypes] = useLazyQuery(GET_MASTER_JOB_TYPES)

  useEffect(() => {
    getMasterJobTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) }
    })
  }, [isActive, jobGroupCode, jobflag])

  useEffect(() => {
    if (getMasterJobTypes.data) {
      const exendedValue = [
        ...(getMasterJobTypes?.data?.getMasterJobTypes || []),
        {
          jobTypeCode: '11',
          jobTypeNameTH: 'งานตรวจสอบในแผน',
          jobTypeNameEN: 'In plan audit'
        }
      ]
      isSetData(
        _.map(exendedValue, (item) => {
          return {
            label: displayText(item?.jobTypeNameTH, item?.jobTypeNameEN),
            value: item?.jobTypeCode,
            source: item
          }
        })
      )
    }
  }, [getMasterJobTypes.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterJobTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectJobTypesCustom
