import { gql } from '@apollo/client'

export const GET_GOAL_AUDIT_WORK_PAGINATION = gql`
  query getGoalAuditWorkPagination($input: GoalAuditWorkPaginationInput!) {
    getGoalAuditWorkPagination(input: $input) {
      result {
        no
        auditJobTargetId
        auditJobTargetName
        jobTypeName {
          nameTh
          nameEn
        }
        organizationName {
          nameTh
          nameEn
        }
        startDate
        endDate
      }
      count
      page
      limit
    }
  }
`

export const GET_GOAL_AUDIT_WORK_DETAIL = gql`
  query getGoalAuditWorkDetail($input: GoalAuditWorkDetailInput!) {
    getGoalAuditWorkDetail(input: $input) {
      auditJobTargetId
      auditJobTargetName
      jobTypeCode
      organizationCode
      startDate
      endDate
    }
  }
`

export const GET_MASTER_JOB_TYPES = gql`
  query getMasterJobTypes($input: MasterJobTypesInput!) {
    getMasterJobTypes(input: $input) {
      jobTypeId
      jobTypeCode
      jobTypeNameTH
      jobTypeNameEN
      jobGroupCode
      isActive
    }
  }
`
