import { gql } from '@apollo/client'

export const SAVE_GOAL_AUDIT_WORK = gql`
  mutation saveGoalAuditWork($input: GoalAuditWorkInput!) {
    saveGoalAuditWork(input: $input) {
      auditJobTargetId
    }
  }
`

export const DELETE_GOAL_AUDIT_WORK = gql`
  mutation deleteGoalAuditWork($input: GoalAuditWorkDetailInput!) {
    deleteGoalAuditWork(input: $input) {
      auditJobTargetId
    }
  }
`
