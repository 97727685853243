import { Trans } from 'react-i18next'
import { displayText } from '../../../../utilitys/helper'

export const ColumnsGoalAuditWork = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    width: '8%',
    // sorter: true,
    align: 'center',
    className: 'text-nowrap'
  },
  {
    title: <Trans>ชื่องาน</Trans>,
    dataIndex: 'auditJobTargetName',
    width: '20%',
    // sorter: true,
    className: 'text-nowrap'
  },
  {
    title: <Trans>ประเภทงาน</Trans>,
    dataIndex: 'jobTypeName',
    width: '15%',
    // sorter: true,
    className: 'text-nowrap',
    render: (_, record) => displayText(record.jobTypeName.nameTh, record.jobTypeName.nameEn)
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'startDate',
    width: '15%',
    align: 'center',
    // sorter: true,
    className: 'text-nowrap'
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'endDate',
    width: '15%',
    align: 'center',
    // sorter: true,
    className: 'text-nowrap'
  },
  {
    title: <Trans>สายงานตรวจสอบ</Trans>,
    dataIndex: 'organizationName',
    width: '15%',
    // sorter: true,
    className: 'text-nowrap',
    render: (_, record) => displayText(record.organizationName.nameTh, record.organizationName.nameEn)
  },
  {
    title: '',
    dataIndex: 'action',
    width: '12%',
    // sorter: true,
    className: 'text-nowrap'
  }
]
