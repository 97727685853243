import { Trans, useTranslation } from 'react-i18next'
import { numberFormat } from '../../../../../utilitys/helper'

export const pageTitle = 'สรุปผลการดำเนินงานไตรมาส'

export const breadcrumbData = () => {
  const { t } = useTranslation()
  return [{ label: t('แดชบอร์ด'), path: '/dashboard' }, { label: t(pageTitle) }]
}

export const ResultsOfWorkColumns = (dynamicField) => {
  return [
    {
      title: <Trans>{'รายละเอียด'}</Trans>,
      dataIndex: 'detail',
      width: 100,
      align: 'left'
    },
    ...dynamicField.map((field) => ({
      title: field.title,
      children: field.children.map((child) => ({
        title: child.title,
        dataIndex: child.dataIndex,
        width: 50,
        align: 'right',
        render: (val) => numberFormat(val) || '0'
      }))
    })),
    {
      title: <Trans>{'รวม สายงานตรวจสอบ'}</Trans>,
      children: [
        {
          title: <Trans>{'เป้าหมาย'}</Trans>,
          dataIndex: ['summary', 'G'],
          width: 50,
          align: 'right',
          render: (val) => numberFormat(val) || '0'
        },
        {
          title: <Trans>{'การปฏิบัติ'}</Trans>,
          dataIndex: ['summary', 'R'],
          width: 50,
          align: 'right',
          render: (val) => numberFormat(val) || '0'
        }
      ]
    }
  ]
}

export const followWorkStatusBySugetionColumns = () => {
  const whiteSpace = { whiteSpace: 'pre' }
  return [
    {
      title: <Trans>{'ฝ่าย'}</Trans>,
      dataIndex: 'organization',
      width: 100,
      align: 'left'
    },
    {
      title: <Trans>{'รายงาน/ประเด็น'}</Trans>,
      dataIndex: 'total',
      width: 100,
      align: 'left',
      render: (val) => <div style={whiteSpace}>{val}</div>
    },
    {
      title: <Trans>{'การติดตามการดำเนินการตามข้อเสนอแนะ'}</Trans>,
      children: [
        {
          title: <Trans>{'ดำเนินการแล้ว'}</Trans>,
          dataIndex: 'completed',
          width: 50,
          align: 'left',
          render: (val) => <div style={whiteSpace}>{val}</div>
        },
        {
          title: <Trans>{'อยู่ระหว่างดำเนินการ'}</Trans>,
          dataIndex: 'inProgress',
          width: 50,
          align: 'left',
          render: (val) => <div style={whiteSpace}>{val}</div>
        },
        {
          title: <Trans>{'ยังไม่ดำเนินการ'}</Trans>,
          dataIndex: 'notStarted',
          width: 50,
          align: 'left',
          render: (val) => <div style={whiteSpace}>{val}</div>
        }
      ]
    }
  ]
}
