import { ErrorBoundary } from 'react-error-boundary'
import { Button } from 'antd'
import styled from 'styled-components'
import { RedoOutlined } from '@ant-design/icons'

export const ErrorStyled = styled.div`
  .title {
    padding-top: 20px;
    color: #b21e1e;
  }
  pre {
    font-family: 'NotoSansThai';
  }
`

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <ErrorStyled role="alert-error-boundary">
      <h2 className="title">Something went wrong!</h2>
      <pre>⚠️ {error.message}</pre>
      <pre>{error.stack}</pre>
      <Button
        danger
        onClick={resetErrorBoundary}
        icon={<RedoOutlined />}
        style={{ borderColor: '#b21e1e', color: '#b21e1e' }}
      >
        Refresh
      </Button>
    </ErrorStyled>
  )
}

export { ErrorBoundary, ErrorFallback }
