import { gql } from '@apollo/client'

export const GET_AUDIT_RESULT_PAGINATION = gql`
  query AuditResultPaginationGet($input: AuditResultPaginationInput!) {
    auditResultPaginationGet(input: $input) {
      result {
        actionPlanId
        projectCode
        projectName
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        fullName
        flag
        button
      }
      count
      page
      limit
    }
  }
`

export const GET_MASTER_ORGANIZATIONS_FILTERS = gql`
  query getMasterOrganizationsFilters($input: MasterOrganizationsInput!) {
    getMasterOrganizationsFilters(input: $input) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      createdAt
      createdBy
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`

export const GET_AUDIT_RESULT_ISSUE = gql`
  query AuditResultIssueGet($issueId: String) {
    auditResultIssueGet(issueId: $issueId) {
      issueId
      actionPlanId
      projectCode
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      factTH
      factEN
      issueHave
      issueTH
      issueEN
      findingTypeCode
      ISOCode
      ISOSubCode
      riskLevelCode
      issueShow
      riskTH
      riskEN
      effectTH
      effectEN
      auditIssuePersonnels {
        personnelCode
      }
    }
  }
`

export const GET_AUDIT_RESULT_INSPEC_TION = gql`
  query AuditResultInspectionGet($issueId: String) {
    auditResultInspectionGet(issueId: $issueId) {
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
      organizationId
    }
  }
`

export const GET_AUDIT_RESULT_INSPEC_TION_PROJECT = gql`
  query AuditResultInspectionProjectGet($projectCode: String) {
    auditResultInspectionProjectGet(projectCode: $projectCode) {
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
      organizationId
    }
  }
`

export const GET_AUDIT_ISSUE_FILES = gql`
  query GetAuditIssueFiles($issueId: String) {
    getAuditIssueFiles(issueId: $issueId) {
      fileId
      filename
      links
      refFile {
        originalname
        name
        fileType
      }
    }
  }
`

export const GET_AUDIT_ISSUE_PAGINATION = gql`
  query AuditIssuePaginationGet($input: AuditIssuesPaginationInput!) {
    auditIssuePaginationGet(input: $input) {
      result {
        issueId
        rateFacProCode
        rateRiskConCode
        personnelsNameTH
        personnelsNameEN
        rateFacProNameTH
        rateFacProNameEN
        rateRiskRuleTH
        rateRiskRuleEN
        rateRiskConTH
        rateRiskConEN
        factTH
        factEN
        issueTH
        issueEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_CAUSE_OF_ISSUES_PAGINATION = gql`
  query AuditCauseOfIssuesPaginationGet($input: AuditCauseOfIssuesPaginationInput!) {
    auditCauseOfIssuesPaginationGet(input: $input) {
      result {
        issueTH
        issueEN
        causeId
        causeDetailTH
        causeDetailEN
        causeNameTH
        causeNameEN
        rateFacProNameTH
        rateFacProNameEN
        rateFacProCode
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_CAUSE_OF_ISSUES_BY_ID = gql`
  query AuditCauseOfIssuesById($causeId: String!) {
    auditCauseOfIssuesById(causeId: $causeId) {
      causeId
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      causeCode
      causeDetailTH
      causeDetailEN
      isActive
    }
  }
`

export const GET_AUDIT_SUGGEST_OF_ISSUES_PAGINATION = gql`
  query AuditSuggestOfIssuesPaginationGet($input: AuditSuggestOfIssuesPaginationInput!) {
    auditSuggestOfIssuesPaginationGet(input: $input) {
      result {
        suggestPersonId
        suggestId
        suggestTH
        suggestEN
        isActive
        issueTH
        issueEN
        dateExpected
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_SUGGEST_OF_ISSUES_BY_ID = gql`
  query AuditSuggestOfIssuesById($suggestId: String!) {
    auditSuggestOfIssuesById(suggestId: $suggestId) {
      suggestId
      rateFacProCode
      rateRiskRuleCode
      issueId
      rateRiskConCode
      suggestTH
      suggestEN
      refInfoCode
      dateExpected
      suggestTypeCode
      remark
      auditSuggestPersonnels {
        personnelCode
      }
      auditSuggestOrganizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
        organizationId
      }
    }
  }
`

export const GET_EMAIL_AUDIT_SUGGEST_ORGANIZATIONS = gql`
  query GetEmailAuditSuggestOrganizations($suggestId: String) {
    getEmailAuditSuggestOrganizations(suggestId: $suggestId) {
      personnelCode
      email
    }
  }
`

export const GET_EMAIL_COPY = gql`
  query GetEmailCopy($projectCode: String) {
    getEmailCopy(projectCode: $projectCode) {
      email
      personnelCode
    }
  }
`

export const GET_STATEMET_PAGINATION = gql`
  query StatementPaginationGet($input: AuditCauseOfIssuesPaginationInput!) {
    statementPaginationGet(input: $input) {
      result {
        suggestPersonId
        suggestId
        suggestTH
        suggestEN
        isActive
        issueTH
        issueEN
        dateExpected
        auditSuggestExplain {
          explainId
          suggestId
          organizationNameTH
          organizationNameEN
          explainEN
          explainTH
          files {
            fileId
            fileNameLabel
            fileNameGen
            fileType
          }
        }
      }
      count
      page
      limit
    }
  }
`

export const GET_STATEMENT_BY_ID = gql`
  query StatementById($explainId: String!) {
    statementById(explainId: $explainId) {
      explainId
      explainTH
      explainEN
      comment
    }
  }
`

export const GET_AUDIT_ISSUES_PAGINATION = gql`
  query AuditIssuesPaginationGet($input: AuditIssuesPaginationInput!) {
    auditIssuesPaginationGet(input: $input) {
      count
      page
      limit
      result {
        issueId
        issueTH
        issueEN
        successd
      }
    }
  }
`

export const GET_AUDIT_ISSUES_EXPORT = gql`
  query AuditIssuesExport($input: AuditIssuesFormExportInput!) {
    auditIssuesExport(input: $input) {
      message
      fileUrl
    }
  }
`
