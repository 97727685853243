import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../../../../components/spinners/Spinners'
import { HeatmapStyled } from '../../css/HeatmapStyled'
import { RiskButton } from '../riskButton'

import { useGetMasterCauseOfIssue, useGetAuditCauseOfIssueHeatmap, useGetHeaderOrganization } from '../../hooks'

import { heatMapColumns } from '../../utils/columns'
import { displayText } from '../../../../../../utilitys/helper'

const RISK_LEVELS = {
  L: { sort: 1 },
  M: { sort: 2 },
  H: { sort: 3 },
  VH: { sort: 4 }
}

const StatusCauseAndIssueDetectedHeatmap = ({ filterDt, eventFilterFn }) => {
  const { t } = useTranslation()
  const [columns, setColumns] = useState([])
  const [causeOfIssueList, causeOfIssueLoading] = useGetMasterCauseOfIssue()
  const [selectedButton, setSelectedButton] = useState()

  const options = useMemo(() => {
    const { dateStart, dateEnd, organizationCodes } = filterDt
    return { dateStart, dateEnd, organizationCodes }
  }, [filterDt?.dateStart, filterDt?.dateEnd, filterDt?.organizationCodes])

  const [auditCauseOfIssue, auditCauseOfIssueLoading] = useGetAuditCauseOfIssueHeatmap(options)
  const optionOrg = useMemo(() => filterDt?.organizationCode || null, [filterDt?.organizationCode])
  const [headerOrganization, headerOrganizationLoading] = useGetHeaderOrganization(optionOrg)

  const processAuditData = (auditData) => {
    return auditData.map((data) => ({
      ...data,
      sort: RISK_LEVELS[data.riskLevelCode]?.sort || 0
    }))
  }

  const groupByOrganization = (data) => {
    return data.reduce((acc, item) => {
      acc[item.organizationCode] = acc[item.organizationCode] || []
      acc[item.organizationCode].push(item)
      return acc
    }, {})
  }

  const groupByCauseCode = (data) => {
    return data.reduce((acc, item) => {
      acc[item.causeCode] = acc[item.causeCode] || []
      acc[item.causeCode].push(item)
      return acc
    }, {})
  }

  // Initial filter effect
  const initialProjectData = useMemo(() => {
    if (!auditCauseOfIssue) return []
    return auditCauseOfIssue.map((d) => ({
      projectCode: d.projectCode,
      organizationCode: d.organizationCode
    }))
  }, [auditCauseOfIssue])

  const dtList = useMemo(() => {
    if (!headerOrganization || !auditCauseOfIssue) return []

    const processedAuditData = processAuditData(auditCauseOfIssue)
    const groupedByOrg = groupByOrganization(processedAuditData)

    const tableData = headerOrganization.map((org) => {
      const result = {
        data: org,
        key: org.organizationCode,
        organizationIdx: displayText(org.organizationNameTH, org.organizationNameEN)
      }

      const orgAuditData = org.sub.filter((subOrg) => groupedByOrg[subOrg]).flatMap((subOrg) => groupedByOrg[subOrg])

      if (orgAuditData.length) {
        const groupedByCause = groupByCauseCode(orgAuditData)

        Object.entries(groupedByCause).forEach(([causeCode, causeData]) => {
          const groupedByRisk = causeData.reduce((acc, item) => {
            acc[item.riskLevelCode] = acc[item.riskLevelCode] || []
            acc[item.riskLevelCode].push(item)
            return acc
          }, {})

          result[causeCode] = (
            <Row gutter={[2, 2]} justify="center">
              {Object.entries(groupedByRisk).map(([riskLevel, riskData]) => (
                <Col key={riskLevel}>
                  <RiskButton
                    count={riskData.length}
                    projectData={riskData.map((d) => ({
                      projectCode: d.projectCode,
                      organizationCode: d.organizationCode
                    }))}
                    eventFilterFn={eventFilterFn}
                    orgCode={org.organizationCode}
                    causeCode={causeCode}
                    riskLevel={riskLevel}
                    selectedButton={selectedButton}
                    setSelectedButton={setSelectedButton}
                    initialProjectData={initialProjectData}
                  />
                </Col>
              ))}
            </Row>
          )
        })
      }

      return result
    })

    // Add summary row
    const summaryRow = {
      key: 'summary',
      organizationIdx: t('รวม'),
      ...Object.fromEntries(
        Object.entries(groupByCauseCode(processedAuditData)).map(([causeCode, data]) => [causeCode, data.length])
      )
    }

    tableData.push(summaryRow)
    return tableData
  }, [headerOrganization, auditCauseOfIssue, t, selectedButton, eventFilterFn, initialProjectData])

  useEffect(() => {
    if (initialProjectData.length > 0) {
      eventFilterFn(initialProjectData)
    }
  }, [initialProjectData])

  useEffect(() => {
    if (causeOfIssueList) {
      setColumns(heatMapColumns(t, causeOfIssueList))
    }
  }, [causeOfIssueList, t])

  const isLoading = causeOfIssueLoading || auditCauseOfIssueLoading || headerOrganizationLoading

  return (
    <>
      {isLoading && <SpinnersNew />}
      <Row justify="center">
        <Col span={23}>
          <HeatmapStyled>
            <Datatable columns={columns} data={dtList} searchCustom={false} paginationCustom={false} />
          </HeatmapStyled>
        </Col>
      </Row>
    </>
  )
}

StatusCauseAndIssueDetectedHeatmap.propTypes = {
  filterDt: PropTypes.shape({
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    organizationCodes: PropTypes.arrayOf(PropTypes.string),
    organizationCode: PropTypes.string
  }),
  eventFilterFn: PropTypes.func.isRequired
}

export default StatusCauseAndIssueDetectedHeatmap
