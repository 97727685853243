import { gql } from '@apollo/client'

export const GET_SELECT_SUGGEST_TYPE = gql`
  query GetSelectSuggestType($input: MasterSuggestTypeInput!) {
    getMasterSuggestType(input: $input) {
      suggestTypeID
      suggestTypeCode
      suggestTypeName
      isActive
    }
  }
`
