import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuditPlanIndex from './auditplan/plan/AuditPlanIndex'
import AuditProjectsIndex from './auditplan/project/AuditProjectsIndex'
import FormAuditProjectsIndex from './auditplan/project/tab/FormAuditProjectsIndex'
import AuditProjectsIndexNoPlan from './auditprojectnoplan/AuditProjectsIndex'
import FormAuditProjectsIndexNoPlan from './auditprojectnoplan/tab/FormAuditProjectsIndex'

import ApprovePlanIndex from './approveplan/ApprovePlanIndex'
import ApprovePlanDetail from './approveplan/ApprovePlanDetail'
import ApprovePlanProjectDetail from './approveplan/ApprovePlanProjectDetail'

import ApproveAuditProjectNotPlanIndex from './approveauditprojectnotplan/ApproveAuditProjectNotPlanIndex'
import ApproveAuditProjectNotPlanDetail from './approveauditprojectnotplan/ApproveAuditProjectNotPlanDetail'

import ApproveCancelProjectIndex from './approvecancelproject/ApproveCancelProjectIndex'
import ApproveCancelProjectDetails from './approvecancelproject/ApproveCancelProjectDetails'
import GoalAuditWork from './goalauditwork/GoalAuditWork'

/**
 * @function Router
 * @description
 * Module: Audit Plan
 * โมดูล: แผนการตรวจสอบ
 * @returns Routes
 */

const Router = () => {
  return (
    <Routes>
      <Route path="/approveauditplan">
        <Route path="" element={<ApprovePlanIndex menu={'AP02'} />} />
        <Route path="detail">
          <Route path="" element={<ApprovePlanDetail menu={'AP02'} />} />
          {/* <Route path="projectdetail" element={<ApprovePlanProjectDetail />} /> */}
          <Route path="projectdetail">
            <Route path="" element={<ApprovePlanProjectDetail menu={'AP02'} />} />
            <Route path="*" element={<ApprovePlanProjectDetail menu={'AP02'} />} />
            {/* <Route path="personandduration" element={<ApprovePlanProjectDetail />} />
            <Route path="ducuments" element={<ApprovePlanProjectDetail />} /> */}
          </Route>
        </Route>
      </Route>
      <Route path="auditplan">
        <Route path="" element={<AuditPlanIndex menu={'AP01'} />} />
        <Route path="list" element={<AuditPlanIndex menu={'AP01'} />} />
        <Route path="auditproject">
          <Route path="" element={<AuditProjectsIndex menu={'AP01'} />} />
          <Route path="list" element={<AuditProjectsIndex menu={'AP01'} />} />
          <Route path="general/add" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="general/edit" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="team/edit" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="document/edit" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="general/view" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="team/view" element={<FormAuditProjectsIndex menu={'AP01'} />} />
          <Route path="document/view" element={<FormAuditProjectsIndex menu={'AP01'} />} />
        </Route>
      </Route>
      <Route path="offplanprojects">
        <Route path="" element={<AuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="list" element={<AuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="general/add" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="general/edit" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="team/edit" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="document/edit" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="general/view" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="team/view" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
        <Route path="document/view" element={<FormAuditProjectsIndexNoPlan menu={'AP03'} />} />
      </Route>
      <Route path="/approveoffplanprojects">
        <Route path="" element={<ApproveAuditProjectNotPlanIndex menu={'AP04'} />} />
        <Route path="auditprojectnotplandetial">
          <Route path="" element={<ApproveAuditProjectNotPlanDetail menu={'AP04'} />} />
          <Route path="*" element={<ApproveAuditProjectNotPlanDetail menu={'AP04'} />} />
        </Route>
      </Route>
      <Route path="/approvecancelproject">
        <Route path="" element={<ApproveCancelProjectIndex menu={'AP05'} />} />
        <Route path="approvecancelprojectdetails">
          <Route path="" element={<ApproveCancelProjectDetails menu={'AP05'} />} />
          <Route path="*" element={<ApproveCancelProjectDetails menu={'AP05'} />} />
        </Route>
      </Route>
      <Route path="/goalauditwork">
        <Route path="" element={<GoalAuditWork menu={'AP06'} />} />
      </Route>
    </Routes>
  )
}
export default Router
