import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../../components/card/Card'
import { ButtonNew } from '../../../../components/button/Button'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import { statusCauseAndIssueDetectedCrumb } from './utils/constants'
import { useGetOrganizationsInBranch, useExportsStatusCauseAndIssueDetected } from './hooks'
import {
  StatusCauseAndIssueDetectedFilter,
  StatusCauseAndIssueDetectedHeatmap,
  StatusCauseAndIssueDetectedTable
} from './components'

const StatusCauseAndIssueDetectedIndex = (props) => {
  const { menu } = props
  const lang = localStorage.getItem('lang')

  const { t } = useTranslation()

  const [filterDt, setFilterDt] = useState({})
  const [projectCodes, setProjectCodes] = useState([])
  const [eventFilter, setEventFilter] = useState([])

  const organizationCode = useMemo(() => {
    return filterDt?.organizationCode
  }, [filterDt?.organizationCode])

  const eventFilterFn = useCallback((e) => {
    setEventFilter(e)
  }, [])

  const [organizationsInBranchList, organizationsInBranchLoading] = useGetOrganizationsInBranch(organizationCode)
  const [exportsStatusCauseAndIssueDetectedLoading, exportsStatusCauseAndIssueDetectedFn] =
    useExportsStatusCauseAndIssueDetected()

  const setDataFilterFn = (values) => {
    const newFilters = {}

    if (values) {
      for (const [key, value] of Object.entries(values)) {
        if (value !== undefined) newFilters[key] = value
      }
    }

    setFilterDt({ ...newFilters })
  }

  const exportStatusCauseAndIssueFn = () => {
    /* call api to export status cause and issue */
    const setDt = {
      lang,
      fileType: 'excel',
      dateStart: filterDt?.dateStart,
      dateEnd: filterDt?.dateEnd,
      organizationCode: filterDt?.organizationCode || null
    }
    exportsStatusCauseAndIssueDetectedFn(setDt)
  }

  return (
    <>
      {(organizationsInBranchLoading || exportsStatusCauseAndIssueDetectedLoading) && <SpinnersNew />}
      <BreadcrumbNew data={statusCauseAndIssueDetectedCrumb} title={t('สถานะการแสดงสาเหตุและประเด็นข้อตรวจสอบ')} />
      <CardNew
        topic={t('สถานะการแสดงสาเหตุและประเด็นข้อตรวจสอบ')}
        toggledrop={'false'}
        buttontopright="true"
        customRight={
          <ButtonNew type="export" roles={{ type: 'export', menu: menu }} onClick={() => exportStatusCauseAndIssueFn()}>
            {t('ส่งออก')}
          </ButtonNew>
        }
      >
        <StatusCauseAndIssueDetectedFilter menu={menu} getValueFn={(e) => setDataFilterFn(e)} />
      </CardNew>
      <CardNew topic={t('ตารางสายงาน/สาเหตุ')} toggledrop={'false'}>
        <StatusCauseAndIssueDetectedHeatmap
          menu={menu}
          projectCodesFn={(e) => setProjectCodes(e)}
          eventFilterFn={eventFilterFn}
          filterDt={{
            ...filterDt,
            organizationCodes: organizationsInBranchList?.organizationCode.length
              ? organizationsInBranchList?.organizationCode
              : []
          }}
        />
      </CardNew>
      <CardNew topic={t('รายละเอียด')} toggledrop={'false'}>
        <StatusCauseAndIssueDetectedTable
          menu={menu}
          projectCodes={projectCodes}
          eventFilter={eventFilter}
          filterDt={{
            ...filterDt,
            organizationCodes: organizationsInBranchList?.organizationCode.length
              ? organizationsInBranchList?.organizationCode
              : []
          }}
        />
      </CardNew>
    </>
  )
}

StatusCauseAndIssueDetectedIndex.propTypes = {
  menu: PropTypes.string.isRequired
}

export default StatusCauseAndIssueDetectedIndex
