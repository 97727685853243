import React, { useState } from 'react'
import { CardNew as Card } from '../../../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd' //Space
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectJobTypes from '../../../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'

//**************** เปลี่ยน filter เป้น form item ปรกติ
//***** แปะ masterPlanstatus ไว้หน้าบ้าน */
// แปลชื่อ column

export default function GoalAuditWorkFillter(props) {
  const { t } = useTranslation()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  // const langUse = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const { menu } = props
  const formName = 'from-approve-fillter'

  const Responesive = {
    inputFilter: { md: 12, lg: 8 },
    buttonFilter: { md: 24, lg: 24 }
  }

  const onFinish = (values) => {
    props.getValue(values)
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => errorFields.map((er) => console.log(er))

  return (
    <>
      <Card topic={t('Filters')} toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="startDate" label={t('วันที่เริ่มต้น')}>
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMinDate(data), await form.setFieldsValue({ startDate: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ startDate: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="endDate" label={t('วันที่สิ้นสุด')}>
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data), await form.setFieldsValue({ endDate: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ endDate: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="jobTypeCode"
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทงาน')}</LabelNew>}
                  >
                    <SelectJobTypes
                      placeholder={t('เลือกประเภทงาน')}
                      formname={formName}
                      filters={{ jobflag: 1, isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="organizationCode"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('สายงานตรวจสอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname={formName}
                      filters={{ organizationChecked: 2, isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="auditJobTargetName"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่องาน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่องาน')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      form.resetFields()
                      props.getValue({})
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}
