import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { GET_SOFTWARE_VERSION } from './graphql/Query'
import { displayError } from '../../../utilitys/helper'
import Logo from '../../../assets/images/logo_ams.png'

const IconDispaly = styled.div`
  .display-none-icon
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .sc-fotOHu
    .ant-input-suffix {
    display: none;
  }
`

const AppLogo = styled.div`
  @media screen and (min-width: 980px) {
    height: 67px;
    width: 111px;
    margin-right: 1rem;
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const SoftwareVersionIndex = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const arrCrumb = [
    { label: t('รายละเอียดซอฟแวร์'), path: '/administrator/softwareversion' },
    { label: t('รายละเอียดการอัปเดตซอฟแวร์') }
  ]

  const [listData, setListData] = useState([])
  const [curVersion, setCurVersion] = useState(null)

  const [getSoftwareVersionFn, getSoftwareVersion] = useLazyQuery(GET_SOFTWARE_VERSION)

  useEffect(() => {
    getSoftwareVersionFn()
  }, [])

  useEffect(() => {
    if (getSoftwareVersion?.data) {
      const response = getSoftwareVersion?.data?.getSoftwareVersion
      const reverseData = _.reverse(response)
      setListData(reverseData)
      setCurVersion(reverseData[0]?.version)
    }
  }, [getSoftwareVersion?.data])

  useEffect(() => {
    if (getSoftwareVersion?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getSoftwareVersion?.error)),
        onOk() {}
      })
    }
  }, [getSoftwareVersion?.error])

  return (
    <>
      {getSoftwareVersion?.loading && <SpinnersNew />}
      <IconDispaly>
        <Breadcrumb data={arrCrumb} title={t('รายละเอียดซอฟแวร์')} />
        <CardNew topic={t('รายละเอียดการอัปเดตซอฟแวร์')} icon={'List'} toggledrop={'false'}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ md: 8, lg: 8 }}
            wrapperCol={{ md: 24, lg: 14, align: 'left' }}
            layout="horizontal"
            name="user_form"
          >
            {curVersion && (
              <Row align="center" justify="center" style={{ alignItems: 'center' }}>
                <AppLogo />
                <LabelNew>{`version: ${curVersion}`}</LabelNew>
              </Row>
            )}
            <br />
            <LabelNew style={{ marginBottom: '1rem' }}>{t('ประวัติการอัปเดตซอฟแวร์')}</LabelNew>
            {listData &&
              listData.map((d, i) => {
                return (
                  <div key={i}>
                    <Row style={{ marginBottom: 20 }} justify="start">
                      <Col md={2} lg={2}></Col>
                      <Col md={22} lg={22}>
                        <LabelNew>{`v${d.version} (${d.date})`}</LabelNew>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 24 }} justify="center">
                      {d.detail.map((k, j) => {
                        return (
                          <Col md={24} lg={14} key={j} style={{ marginBottom: '0.5rem' }}>
                            <LabelNew type="tab-header-inactive">{`- ${k}`}</LabelNew>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                )
              })}
          </Form>
        </CardNew>
      </IconDispaly>
    </>
  )
}

export default SoftwareVersionIndex
