import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_AUDIT_FOLLOW_UP_ACTIVITY_INSPECTOR = gql`
  query getAuditFollowupActivityInspector($input: GetAuditFollowupActivityInspectorInput!) {
    getAuditFollowupActivityInspector(input: $input) {
      actionConditionDate
      actionStatusNameTH
      actionStatusNameEN
      riskLevelCode
      riskLevelNameTH
      riskLevelNameEN
      organizationCode
      organizationNameTH
      organizationNameEN
      causeCode
      causeNameTH
      causeNameEN
      issueTH
      issueEN
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      namePrefixTH
      namePrefixEN
      dateStart
      projectCode
      projectNameTH
      projectNameEN
    }
  }
`

export const useGetAuditFollowupActivityInspector = (options) => {
  const [data, setData] = useState(null)
  const [getAuditFollowupActivityInspectorFn, getAuditFollowupActivityInspector] = useLazyQuery(
    GET_AUDIT_FOLLOW_UP_ACTIVITY_INSPECTOR
  )

  useEffect(() => {
    const { eventFilter } = options

    if (eventFilter.length) getAuditFollowupActivityInspectorFn({ variables: { input: encryptInput({ eventFilter }) } })
  }, [options])

  useEffect(() => {
    if (getAuditFollowupActivityInspector?.data)
      setData(getAuditFollowupActivityInspector?.data?.getAuditFollowupActivityInspector)
  }, [getAuditFollowupActivityInspector?.data])

  const refresh = () => getAuditFollowupActivityInspector.refetch()

  return [data, getAuditFollowupActivityInspector?.loading, refresh]
}
