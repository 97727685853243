import React from 'react'
import { ButtonNew } from '../../../../../components/button/Button'
import { colors } from '../../../../../configs/styles.config'

const RISK_LEVELS = {
  L: { label: 'Low', color: colors.success },
  M: { label: 'Medium', color: colors.yellow },
  H: { label: 'High', color: colors.orange },
  VH: { label: 'Very High', color: colors.danger }
}

export function RiskButton(props) {
  const {
    projectData,
    eventFilterFn,
    count,
    orgCode,
    causeCode,
    riskLevel,
    selectedButton,
    setSelectedButton,
    initialProjectData
  } = props
  // สร้าง unique key จากทุกข้อมูลที่เกี่ยวข้อง
  const buttonKey = `${orgCode}-${causeCode}-${riskLevel}`
  const isSelected = selectedButton === buttonKey

  return (
    <ButtonNew
      type="miniModule"
      width={45}
      height={40}
      style={{
        textAlign: 'center',
        color: colors.black,
        position: 'relative',
        background: RISK_LEVELS?.[riskLevel]?.color,
        border: isSelected ? '2px solid red' : 'none',
        zIndex: isSelected ? 1 : 'auto',
        transform: isSelected ? 'scale(1.05)' : 'none',
        transition: 'all 0.2s ease'
      }}
      onClick={() => {
        if (isSelected) {
          setSelectedButton(null)
          eventFilterFn(initialProjectData)
        } else {
          setSelectedButton(buttonKey)
          eventFilterFn(projectData)
        }
      }}
    >
      {count}
    </ButtonNew>
  )
}
