import React, { useEffect, useState } from 'react'
import { DataTableStyled } from './css/DataTableStyled'
import { CardNew } from '../../../../components/card/Card'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { Datatable } from '../../../../components/datatable/Datatable'
import { followWorkStatusBySugetionColumns, ResultsOfWorkColumns } from './utils/Constants'
import { useTranslation } from 'react-i18next'

const defaultTable1Data = [
  {
    key: '1',
    detail: 'งานตรวจสอบ',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '2',
    detail: 'งานให้คำปรึกษา',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '3',
    detail: 'งานที่ได้รับมอบหมาย',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '4',
    detail: 'งานสนับสนุน',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '5',
    detail: 'รวม',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  }
]

const defaultTable2Data = [
  {
    key: '1',
    department: 'ฝ่ายตรวจสอบภายใน',
    report: 0,
    total: 0,
    completed: 0,
    inProgress: 0,
    notStarted: 0,
    additional: 0
  },
  {
    key: '2',
    department: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
    report: 0,
    total: 0,
    completed: 0,
    inProgress: 0,
    notStarted: 0,
    additional: 0
  },
  {
    key: '3',
    department: 'รวม',
    report: 0,
    total: 0,
    completed: 0,
    inProgress: 0,
    notStarted: 0,
    additional: 0
  }
]

export default function QuarterlySummaryList(props) {
  const { data1, data2, loading } = props
  const [resultsOfWork, setResultsOfWork] = useState([])
  const [followUpData, setFollowUpData] = useState([])
  const { t } = useTranslation()

  const dynamicResultsOfWorkColumns = ResultsOfWorkColumns([
    {
      title: `${t('ฝ่ายตรวจสอบภายใน')} (IA)`,
      children: [
        { title: t('เป้าหมาย'), dataIndex: ['ORG0000029', 'G'] },
        { title: t('การปฏิบัติ'), dataIndex: ['ORG0000029', 'R'] }
      ]
    },
    {
      title: `${t('ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ')} (ITA)`,
      children: [
        { title: t('เป้าหมาย'), dataIndex: ['ORG0000030', 'G'] },
        { title: t('การปฏิบัติ'), dataIndex: ['ORG0000030', 'R'] }
      ]
    }
  ])

  useEffect(() => {
    if (Array.isArray(data1) && data1?.length > 0) {
      setResultsOfWork(data1)
    } else {
      setResultsOfWork(defaultTable1Data)
    }
  }, [data1])

  useEffect(() => {
    if (Array.isArray(data2) && data2?.length > 0) {
      setFollowUpData(data2)
    } else {
      setFollowUpData(defaultTable2Data)
    }
  }, [data2])

  return (
    <>
      <CardNew topic={t('ตารางผลการดำเนินงาน')} icon="TrendingUp" toggledrop={'false'}>
        <DataTableStyled>
          {loading && <SpinnersSmaillNew />}
          <Datatable
            columns={dynamicResultsOfWorkColumns}
            data={resultsOfWork}
            searchCustom={false}
            enableScroll={{ x: false, y: false }}
            showSizeChanger={true}
          />
        </DataTableStyled>
      </CardNew>
      <CardNew topic={t('ตารางผลการติดตามการดำเนินงานตามข้อเสนอแนะ')} icon="TrendingUp" toggledrop={'false'}>
        <DataTableStyled>
          {loading && <SpinnersSmaillNew />}
          <Datatable
            columns={followWorkStatusBySugetionColumns()}
            data={followUpData}
            searchCustom={false}
            enableScroll={{ x: false, y: false }}
            showSizeChanger={true}
          />
        </DataTableStyled>
      </CardNew>
      <div style={{ paddingBottom: '20px' }} />
    </>
  )
}
