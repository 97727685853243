import { gql } from '@apollo/client'

export const GET_DATA_QUARTERLY_SUMMARY = gql`
  query GetQuarterlySummary($input: QuarterlySummaryGraphInput!) {
    getQuarterlySummary(input: $input) {
      graphData {
        level
        type
        value
      }
      tableData {
        key
        detail
        summary {
          G
          R
        }
        ORG0000029 {
          G
          R
        }
        ORG0000030 {
          G
          R
        }
      }
    }
  }
`

export const GET_DATA_FOLLOWUP_SUMMARY = gql`
  query GetFollowUpSummary($input: QuarterlySummaryGraphInput!) {
    getFollowUpSummary(input: $input) {
      tableData {
        key
        organization
        total
        completed
        inProgress
        notStarted
      }
    }
  }
`

export const GET_DATA_QUARTERLY_SUMMARY_EXPORT = gql`
  query GetQuarterlySummaryExport($input: QuarterlySummaryExportInput!) {
    getQuarterlySummaryExport(input: $input) {
      message
      fileUrl
    }
  }
`
